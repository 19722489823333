<template>
  <v-app id="welcome">
    <div class="logout-btn">
      <v-btn large @click="logout">{{ $t('general.logout') }} <v-icon class="ml-2">fa-sign-out-alt</v-icon></v-btn>
    </div>
    <vue-headful :title="$t('welcomeView.simpleWelcome')" />
    <v-row align="center" class="mygradient mx-0" fluid>
      <v-col cols="12">
        <v-row v-if="showModules" fluid>
          <v-col cols="12">
            <v-row justify="center" class="my-3">
              <v-col cols="12">
                <h1 class="text-center" v-if="!isPng">{{ $t('welcomeView.title') }}</h1>
                <h1 class="text-center" v-else>{{ $t('welcomeView.titlePng') }}</h1>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-row align="center" justify="center">
                  <v-card
                    v-for="module in allModules" :key="module.name"
                    class="ma-3 pa-6" width="200px" height="180px"
                    :href="module.href ? module.href: undefined"
                    :target="module.is_target_blank ? '_blank' : ''"
                    @click="module.frontend_path ? onClickModule(module.frontend_path) : undefined"
                  >
                    <v-col class="d-flex justify-center align-center pb-0" style="height: 58%">
                      <v-avatar color="primary" size="62">
                        <v-icon color="white">
                          {{module.icon}}
                        </v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col class="d-flex justify-center align-center text-center">
                    <span style="font-size: .84rem; font-weight: bold">
                      {{module.name}}
                      <i class="fas fa-external-link-alt ml-2" v-if="module.is_target_blank"/>
                    </span>
                    </v-col>
                  </v-card>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-else-if="loading" align="center" fluid>
          <v-col class="text-center">
            <v-progress-circular indeterminate color="primary"/>
            <p class="text-center my-3">{{ $t('welcomeView.loading') }}</p>
          </v-col>
        </v-row>
        <v-row v-else-if="!showModules" align="center" fluid>
          <v-col class="text-center">
            <h2>{{ $t('general.basicError') }}</h2>
            <p class="py-3"> {{ error }}</p>
          </v-col>
        </v-row>
        <v-row justify="center" class="my-3">
          <v-col cols="12">
            <MadeWithLove class="pt-5"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import MadeWithLove from '@/apps/core/components/MadeWithLove'
import { AUTH0_ENABLED, BASE_URL, IS_PNG } from '@/variables'
import { mapState, mapActions } from 'vuex'
import i18n from '@/i18n'

export default {
  name: 'Welcome',
  components: { MadeWithLove },
  data () {
    return {
      loading: true,
      error: null,
      isPng: IS_PNG,
      adminCard: { name: 'Admin Site', href: `${BASE_URL}/unadmin`, icon: 'fa-user-cog' }
    }
  },
  created () {
    // comment to test build
    this.getCosmosModuleByUser()
      .then(() => {
        if (this.allModules.count === 1) {
          const nameView = this.allModules[0].frontend_path
          this.$router.push({ name: nameView })
        }
      })
      .catch(() => {
        this.error = i18n.tc('general.errorSomethingHappened')
      })
      .finally(() => {
        this.loading = false
      })
  },
  computed: {
    ...mapState(['modules', 'modulesExtra']),
    showModules () {
      return !this.loading && this.error == null
    },
    allModules () {
      return [...this.modules, ...this.modulesExtra]
    }
  },
  methods: {
    ...mapActions(['getCosmosModuleByUser']),
    onClickModule (item) {
      this.$router.push({ name: item })
    },
    logout () {
      this.$store.dispatch('logout')
        .then(() => {
          if (AUTH0_ENABLED) {
            this.$auth.logout()
          }
          this.$router.push('/login')
        })
        .catch(err => {
          alert(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .mygradient {
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, var(--v-primary-lighten3) 100%);
  }

  .logout-btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 36px;
  }
</style>
